.scrollSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.scrollSection {
  width: 100%;
  overflow-y: auto;
  padding-right: 10px;
}
