.assignPeopleSearch {
  display: block;
  padding-top: 25px;
}

.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.cardWrapper span {
  display: block;
}

.pagerWrapper {
  padding-bottom: 30px;
}

.scrollContainer {
  max-height: 400px; 
  min-height: 400px; 
  padding-bottom:10px;
  overflow-y: auto;
}
.dropDownSearch {
  padding-bottom:10px;
}