.donutGridItem {
  padding-right: 25px;
}

.gridItem {
  border-left: 1px solid var(--borders);
  padding: 15px;
  display: flex;
  justify-content: center;
}

.skillLevelItem {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 5px;
}

@media screen and (max-width: 1280px) {
  .gridItem {
    justify-content: flex-start;
  }

  .secondGridItem {
    border-left: none;
  }
}

@media screen and (max-width: 580px) {
  .gridItem {
    border-left: none;
    border-top: 1px solid var(--borders);
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 15px;
  }

  .thirdGridItem {
    padding-bottom: 0px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1280px) {
  .grid {
    grid-template-columns: 60% 40%;
  }
}

@media screen and (max-width: 580px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

