.formCard {
  position: relative;
  padding-bottom: 0;
  margin-bottom: 30px;
}

.formCardChipWrapper {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  user-select: none;
}

.manageRoleFormWrapper {
  padding: 10px 0 0 0;
  border: none;
  background: transparent;
  box-shadow: none;
}

.manageRoleFormFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 30px;
}

.roleFormButton {
  all: unset;
  display: block;
  font-weight: 500;
  color: var(--link-text);
  text-transform: uppercase;
  cursor: pointer;
  will-change: opacity, border-color;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
  border-top: solid 2px transparent;
  border-bottom: solid 2px transparent;
}

.roleFormButton:hover {
  border-bottom-color: var(--primary);
}

.roleFormButton[disabled] {
  color: var(--default);
  pointer-events: none;
}
