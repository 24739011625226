/* OTHER - START */
.topNavMenuHeader {
  padding-left: 10px;
}

.topNavMenuItem {
  border: none;
  padding-left: 10px;
  width: 100%;
  background-color: var(--cards);
  display: flex;
  align-items: center;
  font-family: var(--body);
  text-decoration: none;
  cursor: pointer;
}

.topNavMenuItem:hover {
  border-color: var(--accent-2_2);
  background-color: var(--accent-2_2);
}

.topNavMenuItem.isChecked {
  background-color: var(--background);
}
/* OTHER - END */

/* AVATAR - START */
.topNavMenuAvatarIconContainer {
  width: 30px;
  height: 30px;
}
/* AVATAR - END */
