.iconContainer {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  margin: 0px;
}

.iconContainer svg {
  width: 40px;
  height: 40px;
}

.paragraph a {
  text-decoration: none;
  color: var(--secondary);
}

.paragraph a:hover {
  text-decoration: underline;
}

.content {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  box-shadow: inset 0px 0px 25px var(--inner-shadow-colour);
}

.content.hasIcon {
  padding: 20px 40px 20px 60px;
}

.listItem {
  position: absolute;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: var(--cards);
  box-shadow: 0px 4px 20px var(--outer-shadow-colour);
  pointer-events: all;
  overflow: hidden;

  /* animation */
  will-change: bottom, transform, opacity;
  transition: bottom cubic-bezier(0.455, 0.03, 0.515, 0.955) 120ms, transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 120ms;
}

.listItem.Info {
  border-bottom: 2px solid var(--secondary);
}

.listItem.Error {
  border-bottom: 2px solid var(--r);
}

.listItem.Warn {
  border-bottom: 2px solid var(--a);
}

.listItem.Success {
  border-bottom: 2px solid var(--g);
}

.listItem.closingToast {
  animation: CloseToast 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
}

@keyframes CloseToast {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    pointer-events: none;
  }
}

.list {
  position: fixed;
  bottom: 0px;
  left: 50%;
  z-index: 110;
  transform: translateX(-50%);
  margin: 0px;
  width: 80%;
  max-width: 500px;
  padding: 0px;
  list-style: none;
}
