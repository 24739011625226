.content {
  display: grid;
  grid-template-columns: minmax(0px, 1fr) minmax(0px, 1fr) 180px;
  gap: 30px;
  align-items: center;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.heading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}

.scores {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.progressIndication {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.captions {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
  }

  .scores {
    flex-direction: column;
  }
}

