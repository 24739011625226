.manageRoleDialogBody {
  display: block;
  padding-top: 25px;
}

.manageRoleDialogBody.panelLoading {
  opacity: 0.75;
}

.formCard {
  position: relative;
  padding-bottom: 0;
  margin-bottom: 30px;
}

.manageRoleDialogFooter {
  justify-content: space-between !important;
}

.cardStrip {
  background: var(--validation) !important;
}

.errorCardLayout {
  display: grid;
  grid-template-columns: 50px;
  grid-template-rows: auto auto;
  grid-template-areas: 'icon title' 'icon description';
}

.disabledCard {
  display: none;
}

.formCardsWrapper {
  display: flex;
  gap: 20px; 
}

.formCard {
  flex: 2.5;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.viewRoleHeaderCard {
  flex: 1; 
  display: flex;
  flex-direction: column;
  min-width: 100px;
  margin-bottom: 30px;
}

.viewRoleHeaderCard .cardContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
