.card {
  display: grid;
  grid-template-columns: minmax(0px, 1fr) 215px;
  gap: 15px;
  align-items: center;
}

@media (max-width: 580px) {
  .card {
    grid-template-columns: 1fr;
  }
}

.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
}

.listItem {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.button {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--text);
}
