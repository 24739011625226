.container {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.container:first-child {
  padding-top: 20px;
}

.container:last-child {
  padding-bottom: 20px;
}

.container:not(:last-child) {
  margin-bottom: 30px;
}

.container.invisible {
  visibility: hidden;
}

.divider {
  margin-bottom: 30px;
  border: none;
  border-top: 1px solid var(--borders);
  width: 100%;
}

.pagerContainer {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.card {
  padding: 0px;
}
