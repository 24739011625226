.viewRoleHeaderColumn :global .subtitle {
  max-width: calc(100% - 250px);
}

.viewRoleHeaderColumn .buttonContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 15px;
}

.viewRoleHeaderCards {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-rows: auto;
  gap: 15px;
  padding: 30px 0;
  align-items: stretch;
}
.viewRoleHeaderCardsSingleColumn {
  grid-template-columns: 1fr;
}

.viewRoleHeaderCards .viewRoleHeaderCard :global .heading5 {
  margin-bottom: 3px;
}

.viewRoleHeaderCards .viewRoleHeaderCard :global .body1 {
  color: var(--text_1);
  font-weight: normal;
}

.viewRoleHeaderCards .viewRoleHeaderCard .viewRoleHeaderCardChips {
  padding-top: 10px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-flow: column nowrap;
  gap: 10px;
}

.viewRoleCards {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 50px;
}

.dropdownContainer {
  flex: 1 1 auto;
  min-width: 200px;
}

.viewRoleHeaderColumn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.breadcrumbContainer {
  display: flex;
  flex-direction: column;
}

.titleContainer {
  margin-top: 10px;
}
