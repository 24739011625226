.bodyLayout {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  margin-top: 20px;
}

.header {
  display: grid;
  grid-template-areas: 'provider' 'title' 'imageDescription';
  gap: 5px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--borders);
}

.headerImage > img {
  width: 270px;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 30px;
  margin-top: 50px;
}

@media screen and (max-width: 768px) {
  .bodyLayout {
    padding: 20px 20px;
  }
  .headerDescription {
    width: 100%;
  }

  .headerImage > img {
    width: 100%;
  }
}

