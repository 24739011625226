.skillCardsHeader {
  padding-bottom: 20px;
}

.skillCardsHeader :global .body1 {
  font-weight: bold;
  font-size: 18px;
}

.skillCardsHeader :global .caption1 {
  display: block;
  color: var(--text_1);
}

.skillCardsContainer {
  padding: 20px 0;
}

.skillCard {
  padding: 10px 18px;
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  grid-template-rows: auto;
  align-items: center;
  gap: 10px;
}

.skillCardContent {
  overflow: hidden;
  text-overflow: ellipsis;
}

.skillCard .skillCardContent :global .subtitle {
  color: var(--lilac);
  font-weight: bold;
}

.skillCard .skillCardContent :global .caption2 {
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  padding-right: 50px;
}

