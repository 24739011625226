.assignSkillsSearch {
  display: block;
  padding-top: 25px;
}

.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.cardWrapper span {
  display: block;
}

.cardWrapper.addedCardWrapper {
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 15px;
}

.pagerWrapper {
  padding-bottom: 30px;
}

.cardSelect {
  width: 250px;
}

.scrollContainer {
  max-height: 400px; 
  min-height: 400px; 
  overflow-y: auto;
  padding-bottom:10px;

}
.dropDownSearch {
  padding-bottom:10px;
}