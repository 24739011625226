.cardWrapper {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background: var(--cards);
  border: 1px solid var(--borders);
}

.cardTitle {
  text-align: left;
  height: 36px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.cardContent {
  display: flex;
  align-items: left;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  text-align: left;
}

.skillScore {
  width: fit-content;
  display: flex;
  gap: 30px;
}

.skillScoresContainer {
  display: flex;
  gap: 30px;
}

