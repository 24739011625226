.personCardsHeader {
  padding-bottom: 20px;
}

.personCardsHeader :global .body1 {
  font-weight: bold;
  font-size: 18px;
}

.personCardsHeader :global .caption1 {
  display: block;
  color: var(--text_1);
}

.personCardsContainer {
  padding: 20px 0;
}

.personCardWrapper {
  display: grid;
  grid-template-columns: 50px 1fr auto;
  grid-template-rows: auto;
  align-items: center;
  gap: 15px;
  padding: 15px 5px;
}

.personCardIcon {
  border-radius: 50%;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border: solid 1px var(--text);
}

.personCardContent {
  display: flex;
  flex-flow: column nowrap;
  gap: 5px;
}

.personCardContent :global .caption2 {
  color: var(--lilac);
}

.personCardAction {
  display: block;
}

