.footerStyling {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-top: 20px;
}

.confirmationModalHeader {
  width: 100%;
  display: block !important;
}

.confirmationModalHeader h2,
.confirmationModalHeader h6 {
  display: block;
  width: 100%;
}

