.label {
  color: var(--accent-3);
  font-size: 18px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.submitContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}
