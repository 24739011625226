h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong,
p strong {
  color: var(--secondary);
}

*::-webkit-scrollbar {
  background: var(--background);
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 4px;
  border: solid 2px var(--primary_1);
}

button {
  padding: 0;
  margin: 0;
}

input:-webkit-autofill {
  caret-color: var(--text);
}

main {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-top: 90px;
  background-color: var(--background);
  overflow: hidden;
  will-change: padding-left, opacity;
  transition: padding-left cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms, opacity cubic-bezier(0.55, 0.085, 0.68, 0.53) 300ms, transform cubic-bezier(0.55, 0.085, 0.68, 0.53) 300ms;
}

dialog {
  margin: 0;
  max-width: 100vw;
  max-height: 100vh;
  border: none;
  padding: 0;
  color: var(--text);
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
  pointer-events: none !important;
}
